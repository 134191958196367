<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="icon icon-tabler icon-tabler-rotate-2"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    stroke-width="2"
    stroke="currentColor"
    fill="none"
    stroke-linecap="round"
    stroke-linejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path d="M15 4.55a8 8 0 0 0 -6 14.9m0 -4.45v5h-5"></path>
    <line x1="18.37" y1="7.16" x2="18.37" y2="7.17"></line>
    <line x1="13" y1="19.94" x2="13" y2="19.95"></line>
    <line x1="16.84" y1="18.37" x2="16.84" y2="18.38"></line>
    <line x1="19.37" y1="15.1" x2="19.37" y2="15.11"></line>
    <line x1="19.94" y1="11" x2="19.94" y2="11.01"></line>
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
